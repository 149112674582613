.h1 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.83rem;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.h2 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.h2Regular {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.h3SemiBold {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.219rem;
}

.h3 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.219rem;
}

.h6 {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.paragraph {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.small {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.xSmall {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
}

.xSmallMedium {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
}

.xSmallSemiBold {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}

.placeholder {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1rem;
}

.hover {
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 1rem;
}

.button {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
}
